import { useState, useEffect } from 'react';
import { Container } from '../styles/globalStyle';
import styled from 'styled-components';
import Visiting from '../components/Play/Visiting';
import Repertoire from '../components/Play/Repertoire';
import { Poster } from '../components/Play/type';

const Play = () => {
    const [play, setPlay] = useState<Poster[]>(PlayPoster);
    const [visit, setVisit] = useState<Poster[]>([]);
    const [repertoire, setRepertoire] = useState<Poster[]>([]);

    useEffect(() => {
        setVisit(
            play.filter((item: any) => item.category === 'visit').reverse(),
        );
        setRepertoire(
            play
                .filter((item: any) => item.category === 'repertoire')
                .reverse(),
        );
    }, [play]);

    return (
        <Container>
            <PlayContainer>
                <Visiting posters={visit} />
                <Repertoire posters={repertoire} />
            </PlayContainer>
        </Container>
    );
};

export default Play;

const PlayContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PlayPoster = [
    {
        id: 1,
        title: '2018 안녕스물아홉',
        posterUrl: '/assets/play/2018_안녕스물아홉.jpg',
        category: 'repertoire',
    },
    {
        id: 2,
        title: '2019 뮤지컬 갈라콘서트',
        posterUrl: '/assets/play/2019_뮤지컬_갈라콘서트.jpg',
        category: 'repertoire',
    },
    {
        id: 3,
        title: '2019 안녕스물아홉',
        posterUrl: '/assets/play/2019_안녕스물아홉.jpg',
        category: 'repertoire',
    },
    {
        id: 4,
        title: '힘찬미소',
        posterUrl: '/assets/play/2020_힘찬미소.jpg',
        category: 'repertoire',
    },
    {
        id: 5,
        title: '기후위기1인극프로젝트',
        posterUrl: '/assets/play/2020_기후위기1인극프로젝트.jpg',
        category: 'repertoire',
    },
    {
        id: 6,
        title: '원더풀데이',
        posterUrl: '/assets/play/2020_원더풀데이.jpg',
        category: 'repertoire',
    },
    {
        id: 7,
        title: '안녕펭구',
        posterUrl: '/assets/visit/visit2.jpg',
        category: 'visit',
    },
    {
        id: 8,
        title: '우리의 지구 할아버지',
        posterUrl: '/assets/visit/visit1.jpg',
        category: 'visit',
    },
];
