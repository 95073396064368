import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import textStyles from '../../styles/textStyles';
import {
    faBars,
    faAngleDown,
    faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Style = {
    isOpen?: boolean;
    showSubMenu?: boolean;
};

const index = () => {
    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);
    const [clickSubMenuId, setClickSubMenuId] = useState(-1);

    const handlerMenubar = () => {
        setOpenMenu(!openMenu);
        setClickSubMenuId(-1);
    };

    const goToPage = (item: any) => {
        const hasSubMenu = item.id === 1 || item.id === 5;

        if (!hasSubMenu) {
            navigate(`/${item.link}`);
            setOpenMenu(false);
        } else {
            setClickSubMenuId(clickSubMenuId === item.id ? -1 : item.id);
        }
    };

    return (
        <Container>
            <LogoBox>
                <StyledLink to="/" onClick={() => setOpenMenu(false)}>
                    <LogoIcon src={'/assets/playdome-header.png'} />
                </StyledLink>
                <MenubarIcon icon={faBars as IconProp} size="sm" onClick={handlerMenubar} />
            </LogoBox>

            <MenuContainer isOpen={openMenu}>
                {NavMenu.map((item: any, index: number) => (
                    <Fragment key={index}>
                        <MenuItem onClick={() => goToPage(item)}>
                            {/* <StyledLink> */}
                            <MainMenu>{item.menu}</MainMenu>
                            {/* </StyledLink> */}

                            {item.subMenu &&
                                (item.id === clickSubMenuId ? (
                                    <ArrowCloseIcon
                                        icon={faAngleUp as IconProp}
                                        size="2x"
                                    />
                                ) : (
                                    <ArrowOpenIcon
                                        icon={faAngleDown as IconProp}
                                        size="2x"
                                    />
                                ))}

                            {NavMenu[index].subMenu && (
                                <SubMenuContainer>
                                    <HiddenSubMenuBox />
                                    <VisibleSubMenuBox>
                                        {NavMenu[index].subMenu?.map(
                                            (
                                                subItem: any,
                                                subIndex: number,
                                            ) => (
                                                <SubMenuItem
                                                    key={subIndex}
                                                    to={subItem.link}>
                                                    {subItem.title}
                                                </SubMenuItem>
                                            ),
                                        )}
                                    </VisibleSubMenuBox>
                                </SubMenuContainer>
                            )}
                        </MenuItem>

                        {/* only Mobile */}
                        {NavMenu[index].subMenu && (
                            <MobileSubMenuContainer
                                showSubMenu={item.id === clickSubMenuId}>
                                {NavMenu[index].subMenu?.map(
                                    (subItem: any, subIndex: number) => (
                                        <MobileSubMenuItem
                                            key={subIndex}
                                            to={subItem.link}
                                            onClick={() => setOpenMenu(false)}>
                                            {subItem.title}
                                        </MobileSubMenuItem>
                                    ),
                                )}
                            </MobileSubMenuContainer>
                        )}
                    </Fragment>
                ))}
            </MenuContainer>
        </Container>
    );
};

export default index;

const Container = styled.div`
    height: 100px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #333;

    @media ${({ theme }) => theme.device.desktop} {
        flex-direction: column;
        padding: 10px 20px;
    }
`;

const LogoBox = styled.div`
    display: flex;
    align-items: center;

    @media ${({ theme }) => theme.device.desktop} {
        width: 100%;
        justify-content: space-between;
    }
`;

const StyledLink = styled(Link)`
    color: #333;
    text-decoration: none;
`;

const LogoIcon = styled.img`
    height: 80px;

    @media ${({ theme }) => theme.device.desktop} {
        height: 60px;
    }
`;

const MenubarIcon = styled(FontAwesomeIcon)`
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        height: 60px;
        display: inline-block;
        cursor: pointer;
    }
`;

const MenuContainer = styled.div<Style>`
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${({ theme }) => theme.device.desktop} {
        width: 100%;
        flex-direction: column;
        display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
        align-items: flex-start;
    }
`;

const MenuItem = styled.div`
    padding: 0 20px;

    :hover {
        cursor: pointer;
    }

    @media ${({ theme }) => theme.device.desktop} {
        width: 100%;
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
`;

const MainMenu = styled(textStyles.B20)`
    cursor: pointer;
`;

const ArrowCloseIcon = styled(FontAwesomeIcon)`
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        margin: 0 10px;
        display: inline-block;
        cursor: pointer;
    }
`;

const ArrowOpenIcon = styled(FontAwesomeIcon)`
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        margin: 0 10px;
        display: inline-block;
        cursor: pointer;
    }
`;

const SubMenuContainer = styled.div<Style>`
    position: absolute;
    z-index: 1;
    display: none;

    ${MenuItem}:hover && {
        display: block;
    }

    @media ${({ theme }) => theme.device.desktop} {
        ${MenuItem}:hover && {
            display: none;
        }
    }
`;

const HiddenSubMenuBox = styled.div`
    visibility: hidden;
    padding: 18px 10px;

    cursor: none;
`;

const VisibleSubMenuBox = styled.div`
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const SubMenuItem = styled(Link)`
    padding: 12px 10px;
    color: #333;
    text-decoration: none;
    display: block;
    :hover {
        color: ${({ theme }) => theme.colors.purple};
        background-color: #f1f1f1;
    }
`;

const MobileSubMenuContainer = styled.div<Style>`
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        display: ${({ showSubMenu }) => (showSubMenu ? 'block' : 'none')};
    }
`;

const MobileSubMenuItem = styled(Link)`
    @media ${({ theme }) => theme.device.desktop} {
        padding: 10px 5px;
        color: #333;
        display: block;
        text-decoration: none;
    }
`;

const NavMenu = [
    {
        id: 1,
        menu: '극단플레이돔',
        subMenu: [
            {
                title: '극단플레이돔 소개 및 연혁',
                link: 'info',
            },
            {
                title: '오시는 길',
                link: 'map',
            },
        ],
    },
    {
        id: 2,
        menu: '공연소개',
        link: 'play',
    },
    {
        id: 3,
        menu: '온스테이지',
        link: 'onStage',
    },
    {
        id: 4,
        menu: '뮤지컬스테이지',
        link: 'musical',
    },
    {
        id: 5,
        menu: '극단소식',
        subMenu: [
            {
                title: '공지사항',
                link: 'notice',
            },
            {
                title: '언론보도자료',
                link: 'news',
            },
        ],
    },
];
