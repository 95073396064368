import styled from 'styled-components';
import textStyles from '../../styles/textStyles';
import { Posters } from './type';

const Repertoire = ({ posters }: Posters) => {
    return (
        <Container>
            <CategoryText>레파토리</CategoryText>
            <CategoryContent>
                {!!posters && posters.length > 0 ? (
                    <>
                        {posters?.map((poster: any, index: number) => (
                            <ShowBox key={index}>
                                <PosterImage
                                    src={poster.posterUrl}
                                    alt={poster.title}
                                />
                                <PosterTitle>{poster.title}</PosterTitle>
                            </ShowBox>
                        ))}
                    </>
                ) : (
                    <NotMsg>불러오는 중 입니다.</NotMsg>
                    // <NotMsg>현재 공연이 없습니다.</NotMsg>
                )}
            </CategoryContent>
        </Container>
    );
};

export default Repertoire;

const Container = styled.div`
    margin: 20px 0;
`;
const CategoryText = styled(textStyles.B40)``;
const CategoryContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
`;

const ShowBox = styled.div`
    margin: 10px 25px 10px 0;
    width: 230px;
`;
const PosterImage = styled.img`
    width: 100%;
    height: 300px;
`;
const PosterTitle = styled(textStyles.L20)`
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NotMsg = styled(textStyles.L20)`
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
