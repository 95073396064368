import styled from 'styled-components';

const Thin = styled.span`
    font-weight: 100;
`;

const Light = styled.span`
    font-weight: 300;
`;

const Regular = styled.span`
    font-weight: 400;
`;

const Medium = styled.span`
    font-weight: 500;
`;

const Bold = styled.span`
    font-weight: 700;
`;

const L15 = styled(Light)`
    font-size: 15px;
`;

const B18 = styled(Bold)`
    font-size: 18px;
`;

const T20 = styled(Thin)`
    font-size: 20px;
`;

const L20 = styled(Light)`
    font-size: 20px;
`;

const R20 = styled(Regular)`
    font-size: 20px;
`;

const M20 = styled(Medium)`
    font-size: 20px;
`;

const B20 = styled(Bold)`
    font-size: 20px;
`;

const T25 = styled(Thin)`
    font-size: 25px;
`;

const B25 = styled(Bold)`
    font-size: 25px;
`;

const R40 = styled(Regular)`
    font-size: 40px;
`;

const B40 = styled(Bold)`
    font-size: 40px;
`;

const B60 = styled(Bold)`
    font-size: 60px;
`;

const textStyles = {
    L15,
    B18,
    T20,
    L20,
    R20,
    M20,
    B20,
    T25,
    B25,
    R40,
    B40,
    B60,
};

export default textStyles;
