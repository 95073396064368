import React from 'react';
import styled from 'styled-components';
import textStyles from '../../styles/textStyles';
import { useNavigate } from 'react-router';

const NoticeBox = ({ notice }: any) => {
    const navigate = useNavigate();
    return (
        <Container>
            <HeaderBox>
                <Header>공지사항</Header>
            </HeaderBox>
            <ContentBox>
                {notice.map((post: any, index: number) => (
                    <Link key={index}>
                        <Text
                            onClick={() =>
                                navigate(`/notice/${post.idx}`)
                            }>
                            ∙ {post.title}
                        </Text>
                    </Link>
                ))}
            </ContentBox>
        </Container>
    );
};

export default NoticeBox;

const Container = styled.div`
    width: 500px;
    display: flex;
    padding: 20px 40px;
    flex-direction: column;
`;

const HeaderBox = styled.div``;

const Header = styled(textStyles.B25)``;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Link = styled.div`
    margin: 10px 0;
`;

const Text = styled(textStyles.L15)`
    cursor: pointer;
`;
