import { Container } from '../styles/globalStyle';
import styled from 'styled-components';
import textStyles from '../styles/textStyles';
import KakaoMap from '../components/Map/KakaoMap';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

const Map = () => {
    return (
        <Container>
            <MapText>오시는 길</MapText>
            <KakaoMap />
            <AddressContainer>
                <TitleBox>
                    <RoomOutlinedIcon />
                    <Title>주소</Title>
                </TitleBox>
                <Content>경남 창원시 의창구 창이대로383번길 33</Content>
            </AddressContainer>
            <AccessContainer>
                <TitleBox>
                    <DirectionsBusIcon />
                    <Title>대중교통</Title>
                </TitleBox>
                <Content>
                    58, 100, 122, 214, 502번 버스 <br />
                    창원역사민속관 하차 (도보 1분)
                </Content>
            </AccessContainer>
        </Container>
    );
};

export default Map;

const MapText = styled(textStyles.B40)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
`;

const TitleBox = styled.div`
    display: flex;
    align-items: center;
`;
const Title = styled(textStyles.B25)`
    margin: 0 5px;
`;
const Content = styled(textStyles.T25)`
    margin: 10px 5px;
`;

const AccessContainer = styled(AddressContainer)``;
