const colors = {
    purple: '#6667ab',
};

const size = {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
};

const device = {
    mobile: `screen and (max-width: ${size.mobile})`,
    tablet: `screen and (max-width: ${size.tablet})`,
    desktop: `screen and (max-width: ${size.desktop})`,
};

const theme = {
    colors,
    device,
};

export default theme;
