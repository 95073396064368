import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
};

const BANNER = [
    { id: 1, url: '/assets/home/banner1.jpg' },
    { id: 2, url: '/assets/home/banner2.jpg' },
    { id: 3, url: '/assets/home/banner3.jpg' },
    { id: 4, url: '/assets/home/banner4.jpg' },
];

const ImageSlider = () => {
    return (
        <Container>
            <StyledSlider {...settings}>
                {BANNER.map((item, index) => (
                    <ImageContainer key={index}>
                        <Image src={item.url} />
                    </ImageContainer>
                ))}
            </StyledSlider>
        </Container>
    );
};

export default ImageSlider;

const Container = styled.div`
    height: 500px;
    overflow: hidden;
`;

const ImageContainer = styled.div`
    height: 500px;
    position: relative;
`;

const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledSlider = styled(Slider)`
    height: 500px;
    .slick-prev {
        position: absolute;
        left: 5px;
        color: white;
    }
    .slick-next {
        position: absolute;
        right: 50px;
        color: white;
    }
`;
