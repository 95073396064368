import styled from 'styled-components';
import textStyles from '../../styles/textStyles';
import PlaydomeLogo from '../../assets/playdome_black.jpg';

const Playdome = () => {
    return (
        <InfoBox>
            <TitleBox>
                <Title>극단 플레이돔</Title>
            </TitleBox>
            <LogoBox>
                <Logo src={PlaydomeLogo} alt="플레이돔 로고" />
            </LogoBox>
            <SloganBox>
                <Slogan>“자유롭게 놀며 표현하는 예술의 집”</Slogan>
            </SloganBox>
            <ContentBox>
                <Content>
                    극단플레이돔은 창의성과 전문성을 토대로 <br />
                    연간 1회 이상 창작공연을 만들어 많은 관객과 문화예술을
                    나누는 것이 목표이며, <br />
                    더 나아가 지역예술 문화발전에 기여하고자 합니다. <br />
                    <br />
                    2-30대의 청년예술인들로 구성되어 있으며, <br />
                    사회 문제 (환경, 청년) 및 사회적 약자들의 메시지를 예술로
                    전달하고 있습니다. <br />
                    또한 시민 연기 워크샵, 뮤지컬 워크샵 등 지역민의 문화
                    참여기회 제공을 통해 <br />
                    지역극단 및 지역예술가 홍보 및 양성에 힘쓰고 있습니다.
                </Content>
            </ContentBox>
        </InfoBox>
    );
};

export default Playdome;

const InfoBox = styled.div``;

const TitleBox = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled(textStyles.B60)``;

const LogoBox = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    width: 300px;
`;

const SloganBox = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Slogan = styled(textStyles.R40)`
    text-align: center;
`;

const ContentBox = styled.div`
    margin: 40px 0;
    display: flex;
    align-content: center;
    justify-content: center;
`;

const Content = styled(textStyles.T25)`
    text-align: center;
`;
