import { Container } from '../styles/globalStyle';
import Playdome from '../components/Info/Playdome';
import History from '../components/Info/History';
import styled from 'styled-components';

const Info = () => {
    return (
        <Container>
            <Playdome />
            <DividerBox>
                <Divider />
                <Divider />
                <Divider />
            </DividerBox>
            <History />
        </Container>
    );
};

export default Info;

const DividerBox = styled.div`
    margin: 150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Divider = styled.div`
    margin: 0 40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e3e2e2;
`;
