import styled from 'styled-components';
import textStyles from '../../styles/textStyles';

const History = () => {
    return (
        <InfoBox>
            <TitleBox>
                <Title>극 단 연 혁</Title>
            </TitleBox>
            <HistoryContainer>
                {PlaydomeHistory.map((item: any, index: number) => (
                    <HistoryBox key={index}>
                        <Year>{item.year}</Year>
                        {item.show.map((showItem: any, showIndex: number) => (
                            <ShowBox key={showIndex}>
                                <DateBox>
                                    <Date>{showItem.date}</Date>
                                </DateBox>
                                <ContentBox>
                                    <Content>{showItem.content}</Content>
                                </ContentBox>
                            </ShowBox>
                        ))}
                    </HistoryBox>
                ))}
            </HistoryContainer>
        </InfoBox>
    );
};

export default History;

const InfoBox = styled.div``;

const TitleBox = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled(textStyles.B60)``;

const HistoryContainer = styled.div`
    margin: 100px 0;
    display: flex;
    flex-direction: column;
`;

const HistoryBox = styled.div`
    margin: 25px 0;
    display: flex;
    flex-direction: column;
`;

const Year = styled(textStyles.B40)``;

const ShowBox = styled.div`
    margin: 5px 0;
    display: flex;
`;

const DateBox = styled.div`
    width: 200px;
`;

const Date = styled(textStyles.T25)``;

const ContentBox = styled.div`
    flex: 5;
`;

const Content = styled(textStyles.T25)``;

const PlaydomeHistory = [
    {
        year: 2018,
        show: [
            {
                date: '2018.03.02',
                content: '극단플레이돔 창단',
            },
            {
                date: '2018.12.01',
                content: '뮤지컬 갈라 콘서트',
            },
            {
                date: '2018.12.28~29',
                content: '제1회 정기공연 <안녕스물아홉>',
            },
        ],
    },
    {
        year: 2019,
        show: [
            {
                date: '2019.04.06',
                content: '진해도시재생 ‘벚꽃망울 시범사업’ <우연히, 봄>',
            },
            {
                date: '2019.08.23',
                content:
                    '큰창원 한마음 대축제\n<온 가족이 즐기는 한여름밤의 뮤지컬 갈라콘서트>',
            },
            {
                date: '2019.09.23',
                content: '제 11회 창원 그린엑스포 개막공연',
            },
            {
                date: '2019.12.20~21',
                content:
                    '지역문화예술육성지원사업 제 2회 정기공연\n<안녕 스물아홉> 시즌2',
            },
        ],
    },
    {
        year: 2020,
        show: [
            {
                date: '2020.08.14~16',
                content: '제 3회 정기공연 <힘찬미소>',
            },
            {
                date: '2020.10.12',
                content:
                    '제 12회 창원 그린엑스포 온라인공연\n<출동! 따르릉 지구 수비대>',
            },
        ],
    },
    {
        year: 2021,
        show: [
            {
                date: '2021.09.10',
                content: '창원청년주간 청년빛나제 <청춘포차>',
            },
            {
                date: '2021.10.23~24',
                content:
                    '경남청년센터 청년프로젝트 지원사업\n<기후위기 1인극 프로젝트>',
            },
            {
                date: '2021.11.06',
                content:
                    '경남청년센터 청년프로젝트 지원사업\n<기후위기 1인극 프로젝트>',
            },
            {
                date: '2021.11.20~21',
                content: '제 4회 정기공연 <원더풀데이>',
            },
            {
                date: '2021.11.27',
                content: '찾아가는공연 <우리의 지구할아버지>',
            },
            {
                date: '2021.12.28',
                content: '경상남도 전문예술단체 선정',
            },
        ],
    },
];
