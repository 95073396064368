import { Container } from '../styles/globalStyle';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: 'Noto Sans KR, sans-serif',
        backgroundColor: '#6667ab',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Noto Sans KR, sans-serif',
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
    cursor: 'pointer',
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(idx: number, title: string, date: string) {
    return { idx, title, date };
}

const rows = [
    createData(1, '플레이돔 웹 페이지가 개설되었어요 !', '2022-02-05'),
];

const Notice = ({ notice }: any) => {
    const navigate = useNavigate();

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                번호
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                제목
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                작성일
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow
                                key={row.idx}
                                onClick={() =>
                                    navigate(`/notice/${row.idx}`)
                                }>
                                <StyledTableCell align="center">
                                    {row.idx}
                                </StyledTableCell>
                                <StyledTableCell>{row.title}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.date}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Notice;
