import React from 'react';
import textStyles from '../../styles/textStyles';
import styled from 'styled-components';

const AppolicationBox = () => {
    return (
        <Container>
            <HeaderBox>
                <Header>찾아가는 공연 신청</Header>
            </HeaderBox>
            <ContentBox>
                <Text>
                    <Link
                        href="https://blog.naver.com/playdome/222596119433"
                        target="_blank">
                        &raquo; 극단 플레이돔 2022년 찾아가는 공연
                    </Link>
                </Text>
            </ContentBox>
        </Container>
    );
};

export default AppolicationBox;

const Container = styled.div`
    width: 500px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
`;

const HeaderBox = styled.div``;

const Header = styled(textStyles.B25)``;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Text = styled(textStyles.L15)`
    margin: 10px 0;
    vertical-align: middle;
`;

const Link = styled.a`
    text-decoration-line: none;
    color: black;
`;
