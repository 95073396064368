import React from 'react';
import styled from 'styled-components';
import textStyles from '../styles/textStyles';

const Preparing = () => {
    return (
        <Container>
            <PreparingText>현재 준비중인 페이지 입니다.</PreparingText>
        </Container>
    );
};

export default Preparing;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 250px);
`;

const PreparingText = styled(textStyles.T20)``;
