import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import textStyles from '../styles/textStyles';
import { useParams } from 'react-router';
import { Container } from '../styles/globalStyle';
import { useNavigate } from 'react-router-dom';

const NoticeDetail = () => {
    const navigate = useNavigate();
    const params = useParams();

    return (
        <Container>
            <TitleBox>
                <Title>플레이돔 웹 페이지가 개설되었어요 !</Title>
            </TitleBox>
            <ContentBox>
                <Content>
                    안녕하세요. 극단 플레이돔입니다.
                    <br />
                    플레이돔 웹 페이지가 개설되었습니다.
                    <br />
                    감사합니다.
                </Content>
            </ContentBox>
            <BtnBox>
                <BackTo onClick={() => navigate(-1)}>
                    <Text>돌아가기</Text>
                </BackTo>
            </BtnBox>
        </Container>
    );
};

export default NoticeDetail;

const TitleBox = styled.div`
    background-color: #6667ab;
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    line-height: 1.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const Title = styled(textStyles.B18)`
    color: #fff;
`;

const ContentBox = styled.div`
    padding: 30px 50px;
    min-height: 300px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const Content = styled(textStyles.L15)``;

const BtnBox = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
`;

const BackTo = styled.div`
    padding: 5px;
    background-color: #6667ab;
    border-radius: 4px;
    cursor: pointer;
`;

const Text = styled(textStyles.L15)`
    color: #fff;
`;
