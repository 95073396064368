import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        margin: 0px;
        padding: 0px;
    }
    * {
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }
`;

export const Container = styled.div`
    min-height: calc(100vh - 250px);
    padding: 5vw 12vw;

    @media ${({ theme }) => theme.device.desktop} {
        padding: 2vw;
    }
`;

export const FlexBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: pink;
`;
