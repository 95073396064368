import React from 'react';
import styled from 'styled-components';
import textStyles from '../../styles/textStyles';

const Callbox = () => {
    return (
        <Container>
            <HeaderBox>
                <Header>문의전화</Header>
            </HeaderBox>
            <ContentBox>
                <Phone>055-261-5526</Phone>
                <Text>
                    극단 플레이돔은 여러분께 <br />
                    친절하고 빠른 상담을 약속드립니다.
                </Text>
            </ContentBox>
        </Container>
    );
};

export default Callbox;

const Container = styled.div`
    width: 500px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
`;

const HeaderBox = styled.div``;

const Header = styled(textStyles.B25)``;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const Phone = styled(textStyles.B40)`
    color: ${({ theme }) => theme.colors.purple};
`;

const Text = styled(textStyles.L20)`
    margin: 2px 0;
`;
