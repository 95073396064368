import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import textStyles from '../../styles/textStyles';
import PlaydomeLogo from '../../assets/playdome_white.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const index = () => {
    const ref = useRef<HTMLDivElement>(null);

    const { width } = useWindowDimensions();

    return (
        <Container ref={ref}>
            <LogoBox>
                <Logo src={PlaydomeLogo} alt="플레이돔 로고" />
            </LogoBox>
            <InfoContainer>
                <InfoLineBox>
                    <InfoItem>상호명: 극단플레이돔</InfoItem>
                    <InfoItem>
                        주소: 경남 의창구 창이대로 383번길 33 지하1층 플레이돔
                    </InfoItem>
                </InfoLineBox>
                <InfoLineBox>
                    <InfoItem>대표자: 장현정</InfoItem>
                    <InfoItem>고유번호증: 202-82-63984</InfoItem>
                    <InfoItem>Tel: 055-261-5526</InfoItem>
                    <InfoItem>E-mail: playdome@naver.com</InfoItem>
                </InfoLineBox>
            </InfoContainer>
        </Container>
    );
};

export default index;

const Container = styled.div`
    height: 150px;
    margin-top: auto;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.purple};
`;

const LogoBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
`;

const Logo = styled.img`
    width: 100px;
`;

const InfoContainer = styled.div`
    margin: 0 20px;
    display: flex;
    flex-direction: column;
`;

const InfoLineBox = styled.div`
    margin: 5px 0;
`;

const InfoItem = styled(textStyles.L20)`
    color: #fff;

    ::after {
        content: ' | ';
        margin: 0 5px;
    }

    :nth-last-child(1) {
        ::after {
            content: '';
            margin: 0px;
        }
    }
`;
