import styled from 'styled-components';
import ImageSlider from '../components/Home/ImageSlider';
import NoticeBox from '../components/Home/NoticeBox';
import AppolicationBox from '../components/Home/AppolicationBox';
import Callbox from '../components/Home/Callbox';

const index = ({ notice }: any) => {
    return (
        <Container>
            <ImageSlider />
            <ContentContainer>
                <NoticeBox notice={notice} />
                <AppolicationBox />
                <Callbox />
            </ContentContainer>
        </Container>
    );
};

export default index;

const Container = styled.div`
    min-height: calc(100vh - 230px);
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 690px);
`;
