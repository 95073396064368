import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import { GlobalStyles } from './styles/globalStyle';
import NavigationBar from './components/NavBar';
import Renewal from './pages/Renewal';
import Home from './pages/index';
import Info from './pages/Info';
import Map from './pages/Map';
import Play from './pages/Play';
// import OnStage from './pages/OnStage';
// import Musical from './pages/Musical';
import Notice from './pages/Notice';
import NoticeDetail from './pages/NoticeDetail';
// import News from './pages/News';
import Footer from './components/Footer';

import Preparing from './components/Preparing';
import useWindowDimensions from './hooks/useWindowDimensions';

const App = () => {
    const showNav = () => {
        if (
            document.location.href === 'https://playdomeart.com/hide_home' ||
            document.location.href === 'https://playdomeart.com/hide_info' ||
            document.location.href === 'https://playdomeart.com/hide_map' ||
            document.location.href === 'https://playdomeart.com/hide_play' ||
            document.location.href === 'https://playdomeart.com/hide_musical' ||
            document.location.href === 'https://playdomeart.com/hide_notice' ||
            document.location.href === 'https://playdomeart.com/hide_news' ||
            document.location.href === 'http://localhost:3000/hide_home' ||
            document.location.href === 'http://localhost:3000/hide_info' ||
            document.location.href === 'http://localhost:3000/hide_map' ||
            document.location.href === 'http://localhost:3000/hide_play' ||
            document.location.href === 'http://localhost:3000/hide_onStage' ||
            document.location.href === 'http://localhost:3000/hide_musical' ||
            document.location.href === 'http://localhost:3000/hide_notice/1' ||
            document.location.href === 'http://localhost:3000/hide_notice' ||
            document.location.href === 'http://localhost:3000/hide_news'
        ) {
            return <NavigationBar />;
        }
    };

    const showFooter = () => {
        if (
            document.location.href === 'https://playdomeart.com/hide_home' ||
            document.location.href === 'https://playdomeart.com/hide_info' ||
            document.location.href === 'https://playdomeart.com/hide_map' ||
            document.location.href === 'https://playdomeart.com/hide_play' ||
            document.location.href === 'https://playdomeart.com/hide_musical' ||
            document.location.href === 'https://playdomeart.com/hide_notice' ||
            document.location.href === 'https://playdomeart.com/hide_news' ||
            document.location.href === 'http://localhost:3000/hide_home' ||
            document.location.href === 'http://localhost:3000/hide_info' ||
            document.location.href === 'http://localhost:3000/hide_map' ||
            document.location.href === 'http://localhost:3000/hide_play' ||
            document.location.href === 'http://localhost:3000/hide_onStage' ||
            document.location.href === 'http://localhost:3000/hide_musical' ||
            document.location.href === 'http://localhost:3000/hide_notice/1' ||
            document.location.href === 'http://localhost:3000/hide_notice' ||
            document.location.href === 'http://localhost:3000/hide_news'
        ) {
            return <Footer />;
        }
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {/* {showNav()} */}
                <NavigationBar />
                <Routes>
                    {/* <Route path="/" element={<Renewal />} /> */}
                    <Route
                        path="/"
                        element={<Home notice={NoticeData} />}
                    />
                    <Route path="/info" element={<Info />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/play" element={<Play />} />
                    <Route path="/onStage" element={<Preparing />} />
                    <Route path="/musical" element={<Preparing />} />
                    <Route
                        path="/notice"
                        element={<Notice notice={NoticeData} />}
                    />
                    <Route path="/notice/:id" element={<NoticeDetail />} />
                    <Route path="/news" element={<Preparing />} />
                </Routes>
                <Footer />
                {/* {showFooter()} */}
            </ThemeProvider>
        </>
    );
};

export default App;

const NoticeData = [
    {
        idx: 1,
        title: '플레이돔 웹 페이지가 개설되었어요 !',
        date: '2022-02-05',
    },
];
