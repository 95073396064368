import { useRef, useEffect } from 'react';
import styled from 'styled-components';

declare global {
    interface Window {
        kakao: any;
    }
}

const { kakao } = window;

const KakaoMap = () => {
    useEffect(() => {
        const container = document.getElementById('map');
        const options = {
            center: new kakao.maps.LatLng(33.450701, 126.570667),
            level: 1,
        };
        const map = new kakao.maps.Map(container, options);

        const ps = new kakao.maps.services.Places();

        ps.keywordSearch(
            '경상남도 창원시 의창구 창이대로383번길 33',
            placesSearchCB,
        );

        function placesSearchCB(data: any, status: any) {
            if (status === kakao.maps.services.Status.OK) {
                let bounds = new kakao.maps.LatLngBounds();

                for (let i = 0; i < data.length; i++) {
                    displayMarker(data[i]);
                    bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
                }

                map.setBounds(bounds);
            }
        }

        function displayMarker(place: any) {
            let marker = new kakao.maps.Marker({
                map: map,
                position: new kakao.maps.LatLng(place.y, place.x),
            });
        }
    }, []);

    return <Container id="map" />;
};

export default KakaoMap;

const Container = styled.div`
    margin: 50px 0;
    display: flex;
    width: 100%;
    height: 500px;
    background-color: powderblue;
`;
