import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = {
    gtmId: 'GTM-TX3W3MS'
};

TagManager.initialize(tagManagerArgs);

const element = (
    <Router>
        <App />
    </Router>
);

const container = document.getElementById('root');
ReactDOM.render(element, container);
